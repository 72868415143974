

  /* ================ main navbar styles =========== */
  header{
    background-color: rgba(255, 255, 255);
    min-height: 65px;
  }

  .main__navbar {
    padding: 15px 0px;
    background: rgba(255, 255, 255);
    margin: 0 5%;
    width: 90%;
  }

  .mobile__menu i {
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
  }

  .mobile__menu {
    display: none;
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;

  }

  .nav__item {
    color: black;
    text-decoration: none;
    transition: 0.3s;
  }

  .nav__item:hover {
    color: var(--main-color);
  }

  .search__box {
    border: 1px solid #7c8a972f;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: #020a4de9;
  }

  .search__box input {
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
  }

  .search__box input::placeholder {
    font-size: 0.8rem;
  }
  .search__box span i {
    color: rgba(255, 255, 255, 0.555);
    cursor: pointer;
  }

  .nav__active {
    color: var(--main-color);
  }

  @media only screen and (max-width: 768px) {
    .header__top,
    .header__middle,
    .nav__right {
      display: none;
    }

    .mobile__menu {
      display: block;
    }

    .navigation {
      background: rgb(0, 13, 107, 0.6);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      display: none;
    }

    .menu {
      width: 250px;
      height: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 2rem;
      justify-content: center;
    }

    .menu a {
      color: #000d6b;
      font-weight: 600;
      font-size: 0.9rem;
    }

    .menu__active {
      display: block;
    }
  }
