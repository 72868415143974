.slider__item {
    max-width: 100%;
    min-height: calc(100vh - 65px);
  }

  
  .slider__item-01 {
    background: linear-gradient(rgba(224, 26, 26, 0.405), rgb(0, 13, 107, 0.5)),
      url("../assets/all-images/slider-img/slider-1.jpeg");
    background-position: center 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  
  .slider__item-02 {
    background: linear-gradient(rgba(224, 26, 26, 0.405), rgb(0, 13, 107, 0.5)),
      url("../assets/all-images/slider-img/slider-2.jpg");
    background-position: center 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  
  
  .slider__item-03 {
    background: linear-gradient(rgba(224, 26, 26, 0.405), rgb(0, 13, 107, 0.5)),
      url("../assets/all-images/slider-img/slider-3.jpg");
    background-position: center 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  
  
  .slider__item-04 {
    background: linear-gradient(rgba(224, 26, 26, 0.405), rgb(0, 13, 107, 0.5)),
      url("../assets/all-images/slider-img/slider-4.jpg");
    background-position: center 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  
  .hero__slider {
    overflow: hidden;
    min-height: calc(100vh - 65px);
  }
  
  .slider__content {
    padding-top: 150px;
  }
/*   
  .slider__content h1 {
    font-size: 3.2rem;
    font-family:Custom-Demi;
     font-weight: 500;
     text-transform:uppercase;
     text-align: center;
   
    
  } */
  
  .reserve__btn {
    background: #fff ;
    color: #000d6b ;
    border: none;
    outline: none;
  }
  
  .reserve__btn a {
    text-decoration: none;
    font-weight: 600;
    color: #000d6b;
  }
  
  .reserve__btn:hover {
    background: #000d6b;
    color: #fff;
  }
  
  @media only screen and (max-width: 1000px) {
    .slider__item {
      /* height: 530px; */
      /* max-width: 100vw; */
    }
  
    .slider__content {
      margin-top: -140px;

    }
  
    .slider__content h1 {
      font-size: 2rem;
      text-align: center;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .slider__item {
      /* height: 470px; */
    }
  
    .slider__content h1 {
      font-size: 2rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: 576px) {
    
  
    .slider__content h1 {
      font-size: 1.6rem;
      text-align: center;
;
    }
  }
  body{
    background-color: rgb(203, 213, 213);
  }