.transparent-form h3 {
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 20px;
}

.transparent-form {
  /* position: absolute;
  top: 95px;
  left: 50%;
  transform: translateX(-50%); */
  /* min-height: 40%; */
  /* width:  calc(100% - 40px); */
  width: calc(100% - 65px);
  /* z-index: 1; */
  padding : 40px 25px; 
  /* margin: 0 10px; */
  border-radius: 10px;
  /* background: rgba(255, 255, 255, 0.4); */
  background: rgba(255, 255, 255);
  /* box-shadow: 8px 7px 6px 0px #00000045; */
  box-shadow: 5px 6px 11px 0px #00000045;
}

.inputs_holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.inputs_holder > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
/* .inputs_holder > div label {
  background-color: #000;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  margin: 0 auto;
  font-size: 14px;
} */
.inputs_holder > div select,
.inputs_holder > div input {
  width: 100%;
  padding: 5px 15px;
  height: 35px;
  outline: none;
  color: #7c8a97;
  border: none;
  font-size: 16px;
}

.button_holder {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.button_holder button {
  background: #0c0c0c;
  padding : 7px 50px;
  outline: none;
  border: none;
  font-size: 17px;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
  border-radius: 4px;
}

@media screen and (min-width: 668px) {
  .transparent-form h3{
    font-size: 26px;
  }

  .inputs_holder > div {
    width: calc(50% - 8px);
  }

  .inputs_holder > div label {
    font-size: 16px;
  }

  .inputs_holder > div select,
  .inputs_holder > div input {
    font-size: 17px;
    height: 40px;
  }
}


