.card{
    width : 95%;
    margin-left: 6%;
    min-height: 466px;
    

}

.card-body{
   display: flex;
    flex-direction: column;

}

.card-text{
  
    flex: 1;

}
.card-info{
    padding: 10px;
    border-radius: 150px;
    border: none;
    background: #0c0c0ca0;
    color: white;
    display: block;
    margin: 0 auto;
    width: 100%;
}
button:hover{
    background-color: var(--main-color);
}
.row{
    margin-top: -3%;}

    .lin{
        text-decoration: none;
        color: black;
    }
 





















