.common__section {
  background: linear-gradient(rgba(224, 122, 26, 0.616), rgb(0, 13, 107, 0.5)),
    url("../assets/all-images/drive.jpg");
  background-position: center;
  background-size:cover;
  background-repeat:no-repeat;
  padding: 20px 0px;
  position : relative;
   min-height : calc(100vh - 65px);
}


.find__cars-left h3 {
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
}

.form__group {
  width: 30%;
  margin-bottom: 0;
}

.form__group input {
  border: 1px solid #4446472a;
  padding: 8px 12px;
  width: 100%;
  outline: none;
}
.form__group input::placeholder {
  color: #080808;
}

.form2 {
  /* background: #fff; */
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(255, 255, 255, 0.5);
  position:relative;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 62%;
  transform: translate(-50%, -50%);
  top: 120px;
  
}


.select__group {
  width: 30%;
}

.select__group select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #7c8a972a;
  outline: none;
  color: #7c8a97;
  
}

.hero__slider-section {
  position: relative;
  z-index: 10;
  margin-bottom: 200px;
}

.hero__form {
  position: absolute;
  bottom: -150px;
  z-index: 11;
  width: 100%;
}

.form__row {
  background: #fff;
  box-shadow: 5px 5px 15px -5px #7c8a97;
  margin-top: -10%;
}

.journey__time::before {
  content: "Journey time";
  margin-right: 0.8rem;
  color: #7c8a97;
}

.find__car-btn {
  background: #0c0c0c !important;
  color: #fff !important;
  padding: 8px 15px;
  width: 30%;
  border: none;
  outline: none;
}

@media only screen and (max-width: 992px) {
  .find__cars-left {
    padding: 65px 22px;  
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
    
  }

 

  .form__group input,
  .select__group select {
    padding: 7px;
    font-size: 0.rem;
    height: 40px;
    
    
  }

  .hero__form {
    bottom: -10px;
  }
  
}

@media only screen and (max-width: 768px) {
  .find__cars-left {
    padding-top: 20px;
    padding-bottom: 0;
    background: #fff;
    text-align: center;
    
    
  }

  .form {
    padding: 20px 10px;
    
  }
  .form2{
    top: 120px;
    
  }
}

@media only screen and (max-width: 576px) {
  .form__group {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }

  .form__group input {
    font-size: 0.7rem !important;
  }

  .form2{
    top: 207px;
  }

  .select__group {
    width: 100%;
    padding-right: 1px;
    font-size: 0.9rem;
  }

  .find__car-btn {
    font-size: 0.8rem;
  }

  .find__cars-left h2 {
    font-size: 1rem;
  }

  .hero__form {
    bottom: -250px;
  }
}
.sect{
  background-color: #dee2e645;
}
