/* .ivimag {
    background: linear-gradient(rgba(224, 122, 26, 0.616), rgb(0, 13, 107, 0.5)),
    url("../assets/all-images/drive.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    padding: 85px 65px;
    
  } */
.sreachbar div select {
  background-color: white;
  border: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 17px;
  padding: 5px 10px;
  height: 30px;
  width: 180px;
  color: #878787;
}
@media only screen and (max-width: 480px) {
  .sreachbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;
  }
  .sreachbar div select {
    flex: 1;
  }
  .search input {
    width: 100%;
    border-radius: 4px;
  }
}

@media only screen and (min-width: 481px) {
  .sreachbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;
  }
  .search input {
    width: 300px;
    height: 30px;
    border-radius: 3px;
  }
}
