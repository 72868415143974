.service__item span i {
    font-size: 2.5rem;
    color: var(--main-color);
    justify-content: center;
    align-items: center;
  }
  
  .service__item h6 {
    font-size: 1.2rem;
    font-weight: 600;
    transition: 0.3s;
    color: black;
    justify-content: center;
    align-items: center;
  }
  .service__item h1{
    color : black;
  }
  
  .service__item h6:hover {
    color: var(--main-color);
    justify-content: center;
    align-items: center;
  }
  .service__item p{
    text-align: center;
  }
  
  
  
  @media only screen and (max-width: 768px) {
    .service__item h6 {
      font-size: 1.1rem;
    }
    .service__item p {
     
      width: 100%;
     }
  }
  
  .service__item p {
    color: #000000bd;
    width: 100%;

   }
   .section__title{
    color: black;
   }
 

  
   col {
    display: flex;
  justify-content: center;
  align-items: center;
   }

   .service__item{
    border: 10x solid;
    background-color: white ;
    justify-content: center;
    padding: 10%;
    margin : 0 20px;
     border-radius: 8px ; 
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
   }



   