.about__section-item {
    column-gap: 3rem;
  }
  
  .about__section-item p i {
    color: var(--main-color);
    font-size: 1.1rem;
  }
  .about__section-content{
    
  }
  
  @media only screen and (max-width: 992px) {
    .about__section-content h2 {
      font-size: 1.5rem;
    }
    .about__section-item {
      column-gap: 3rem;
    }
    .about__section-item p {
      font-size: 0.7rem;
      margin-bottom: 0;
    }
  
    .section__description i {
      font-size: 0.8rem !important;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .about__img {
      margin-top: 0px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .about__section {
      margin-top: 280px;
    }
  }
  .about__section{
    background-color: #dee2e645;

  }