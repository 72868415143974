.section__description{
  color: rgb(75, 74, 74);
  font-size: 16px;
  
  
}
@media only screen and (max-width: 992px) {
    .about__page-content h2 {
      font-size: 1.5rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .about__page-img {
      display: none;
    }
  }
  .about__page-section{
    background-color: #dee2e645;
  }
  