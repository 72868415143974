.social__link-icon {
    text-decoration: none;
  
    color: #000d6b;
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  .contact__btn {
    padding: 7px 15px;
    background: black;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  
  
  @media only screen and (max-width: 768px) {
    .contact__btn {
      margin-bottom: 40px;
      font-size: 0.8rem;
    }
  }
  .p{
    color:rgb(23, 23, 23);
  }
.cont{
    font-size: 3.2rem;
    font-family: Custom-Demi;
    font-weight: 500
}
.title{
  background-color: #fff;
}
