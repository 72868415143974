.common{
    background: linear-gradient(rgba(224, 122, 26, 0.616), rgb(0, 13, 107, 0.5)),
      url("../assets/all-images/car2.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0px;}
    .lig{
        font-size: 3.2rem;
        font-family: Custom-Demi;
        font-weight: 500;
        color: black;
    }