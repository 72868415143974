:root {
  --main-color: #ff0008;
}

.car__item {
    border: 1px solid #7c8a9736;

    border-radius: 15px;
    background:#ffffff;
  }
.ImageCard {
  /*background: */
  /*  url("../assets/all-images/images.jpeg");*/
  /*  background-position: center;*/
  /*  background-size: auto 100%;*/
  /*  background-repeat: no-repeat;*/
  height: 250px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}
.car__item-info {
  padding: 15px;
}

  .car__item-info span i {
    color: var(--main-color);
    font-size: 1.7rem;


  }

  .car__item-btn {
    border-radius: 0;
    border: none;
    outline: none;
    background: black;
    padding: 8px 0px;
  }

  .car__item-btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
  }

  .car__item-btn a:hover {
    color: #fff;
  }

  .car__btn-details {
    background: var(--main-color);
    border-radius: 5px;
    width: fit-content !important;
    padding: 10px 50px;
    transition: .2s;
  }

  .car__btn-details:hover {
    background: #000;
    color: #fff;
  }


  .car__btn-rent {
    border-radius: 4px 0px 0px 4px;
  }

  .car__item-content h4 {
    font-size: 1.8rem;
  }

  .car__item-content h6 {
    font-size: 1.2rem;
    font-weight: 600;

  }



  @media only screen and (max-width: 992px) {
    .car__item h4 {
      font-size: 1.1rem;
      margin-bottom: 0;
    }

    .car__item h6 {
      font-size: 1rem;
      margin-top: 10px;
    }

    .car__item-info {
      flex-wrap: wrap;
    }

    .car__item-info span {
      font-size: 0.8rem;
      justify-content: space-between;
    }

    .car__item-btn {
      padding: 5px 10px;
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .car__item h4 {
      font-size: 1rem;
    }

    .car__item h6 {
      font-size: 0.9rem;
    }

    .car__item-info span {
      font-size: 1.0rem;
    }

    .car__item-info span i {
      font-size: 1.2rem;
    }

    .car__item-btn {
      font-size: 0.8rem;
    }
  }
